/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable-next-line react-hooks/exhaustive-deps */
'use client';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Container } from '@mui/material';
import { GoogleMapContextProvider } from '../../context/google-map-context-provider';
import PlacesAutocomplete from 'apps/bayada/components/places-auto-complete/places-auto-complete';
import { CustomChip } from '@bayada/shared/ui-components';
import RadiusSlider, { MarksProps } from './office-components/radius-slider';
import { GoogleMapApiKey } from 'apps/bayada/constants';
import {
  getLocLatLong,
  getOfficeLocations
} from '../../services/office-service';
import { useSearchParams } from 'next/navigation';
import OfficeMapContainer from './office-components/office-map-container';
import {
  mapOfficeAPI,
  OfficeApiModel,
  OfficeModel
} from './office-data-parser';
import {
  useContentfulInspectorMode,
  useContentfulLiveUpdates
} from '@contentful/live-preview/react';
import { parseOfficeData } from 'apps/bayada/utils/component-data-parser';
import { Entry, EntrySkeletonType } from 'contentful';
import { useAppContext } from '../../context/app-context';
import { ResetScrollRestore } from '@bayada/shared/ui-components';
import { CmsImageProps } from '@bayada/interfaces';
import { isAutoCompleteFieldValid, trimSpaces } from 'apps/bayada/utils/helper';

type Place = google.maps.places.PlaceResult | null | undefined;

/**
 * Defines the properties required for the "Find An Office" page.
 *
 * This type specifies the structure of the data required to render the page, including labels, options, and button texts.
 *
 * @typedef {Object} findAnOfficePageProps
 * @property {string} getDirectionsLabel - Label for the "Get Directions" button.
 * @property {string} internalName - Internal name for the page.
 * @property {string} locationInputAriaLabel - ARIA label for the location input field.
 * @property {string} locationLabel - Label for the location input field.
 * @property {string[]} radiusInMilesOptions - Options for radius selection in miles.
 * @property {string} radiusLabel - Label for the radius selection.
 * @property {string} searchButtonLabel - Label for the search button.
 * @property {string} serviceTypeLabel - Label for the service type selection.
 * @property {serviceDataProps[] | null} services - List of services available on the page.
 * @property {string} serviceAreasLabel - Label for the area where services are offered.
 * @property {string} servicesOfferedLabel - Label for the services offered section.
 * @property {string} visitOfficeButtonText - Text for the button to visit the office.
 * @property {Document | null} noLocationsFound - Document or message displayed when no locations are found.
 * @property {string} shareViaEmailLabel - Label for sharing information via email.
 * @property {string} locationInputLabel - Label for the location input field.
 * @property {string} findOfficeButtonLabel - Label for the "Find Office" button.
 * @property {string} findOfficeButtonAriaLabel - ARIA label for the "Find Office" button.
 * @property {string} selectServiceHelptext - Help text for selecting a service.
 * @property {string} defaultRadius - Default radius value for the search.
 */
export type findAnOfficePageProps = {
  getDirectionsLabel: string;
  internalName: string;
  locationInputAriaLabel: string;
  locationLabel: string;
  radiusInMilesOptions: string[];
  radiusLabel: string;
  searchButtonLabel: string;
  serviceTypeLabel: string;
  services: serviceDataProps[] | null;
  serviceAreasLabel: string;
  servicesOfferedLabel: string;
  visitOfficeButtonText: string;
  noLocationsFound: Document | null;
  shareViaEmailLabel: string;
  locationInputLabel: string;
  findOfficeButtonLabel: string;
  findOfficeButtonAriaLabel: string;
  selectServiceHelptext: string;
  defaultRadius: string;
};

/**
 * Defines the properties for a service item used on the "Find An Office" page.
 *
 * This type specifies the structure of each service item, including its name, ID, and description.
 *
 * @typedef {Object} serviceDataProps
 * @property {string} fullName - Full name of the service.
 * @property {string} masterDataId - Unique identifier for the service.
 * @property {string} shortName - Short name of the service.
 * @property {boolean} [isSelected] - Optional flag indicating if the service is selected.
 * @property {CmsImageProps | null} [icon] - Optional icon associated with the service.
 * @property {string} description - Description of the service.
 */
export type serviceDataProps = {
  fullName: string;
  masterDataId: string;
  shortName: string;
  isSelected?: boolean;
  icon?: CmsImageProps | null;
  description: string;
};

/**
 * Functional component for rendering the Find An Office page.
 * @param {findAnOfiiceProps} props - Props containing data for rendering the page.
 * @returns {JSX.Element} JSX Element representing the Find An Office page.
 */
export const FindAnOfficePage = (props: {
  data: Entry<EntrySkeletonType, undefined, string> | undefined | any;
}) => {
  const { context } = useAppContext();
  const OfficeApibaseURL = context?.constants?.OfficeApibaseURL;
  const updatedState = useContentfulLiveUpdates(props?.data);
  const findOfficePageData = parseOfficeData(updatedState);
  const {
    locationLabel,
    radiusInMilesOptions,
    radiusLabel,
    serviceTypeLabel,
    services,
    locationInputLabel,
    findOfficeButtonAriaLabel,
    findOfficeButtonLabel,
    selectServiceHelptext,
    defaultRadius
  } = findOfficePageData || {};

  const [serviceList, setServiceList] = useState(services);
  const [marks, setMarks] = useState<MarksProps[]>();
  const [stageValue, setStageValue] = useState<number>();
  const [selectedPlace, setSelectedPlace] = useState<Place | string>(null);
  const [offices, setOffices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initialPlace, setInitialPlace] = useState<Place>();
  const [buttonClick, setButtonClick] = useState(false);
  const [isAutocompleteValid, setIsAutocompleteValid] =
    useState<boolean>(false);

  const [findOfficeClick, setFindOfficeClick] = useState<boolean>(false);

  const inspectorProperties = useContentfulInspectorMode();
  const searchParams = useSearchParams();
  const GoogleMapsApiKey = GoogleMapApiKey;

  useEffect(() => {
    setServiceList(services);
    if (marks) {
      const stage = marks?.find(
        (mark: MarksProps) => mark?.label === Number(defaultRadius)
      )?.value;
      setStageValue(stage);
    }
  }, [updatedState]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const officeMapRef = useRef<any>();

  // Access individual query parameters
  const { address, radius, service } = {
    address: searchParams.get('address')?.replace(/\+/g, ' '),
    service: searchParams.get('service')?.replace(/\+/g, ' ') || '',
    radius: searchParams.get('radius')
  };

  const serviceSelected = serviceList?.find(
    (item) => item?.isSelected === true
  );

  /**
   * Handles the marks for the radius slider and sets the stage value accordingly.
   * @param {MarksProps[] | undefined} marks - The array of marks for the radius slider.
   */
  const handleMarks = useCallback(
    (marks: MarksProps[] | undefined) => {
      setMarks(marks);
      const sliderValue = radius ? Number(radius) : Number(defaultRadius);
      const stage = marks?.find(
        (mark: MarksProps) => mark?.label === sliderValue
      )?.value;
      setStageValue(stage);
    },
    [radius, defaultRadius]
  );

  useEffect(() => {
    if (radius && marks?.length) {
      const sliderValue = Number(radius);
      const stage = marks?.find(
        (mark: MarksProps) => mark?.label === sliderValue
      )?.value;
      setStageValue(stage);
    }
  }, [radius]);

  /**
   * Handles the click event on a chip and updates the selected service list and chips accordingly.
   * @param {string | undefined} chipValue - The value of the clicked chip.
   */
  const handleOnChipClick = (chipValue: string | undefined) => {
    updateServiceList(chipValue);
    setButtonClick(false);
  };

  /**
   * Handles the selection of a place from the autocomplete search results.
   * @param {google.maps.places.PlaceResult | null | undefined} place - The selected place object.
   * @param {boolean} [isButtonClick] - Indicates whether the selection was made by clicking a button.
   */

  const handlePlaceSelection = (
    place: google.maps.places.PlaceResult | null | undefined
  ) => {
    setButtonClick(false);
    setIsAutocompleteValid(true);
    setSelectedPlace(place);
    setInitialPlace(place);
  };

  const handleFindOffice = async () => {
    const radius = marks?.at(stageValue ?? 2);
    /* The code is constructing a URL string for a specific endpoint `/find-an-office` with query
    parameters based on the values of `selectedPlace`, `serviceSelected`, and `radius`. */
    //let url = `/find-an-office?address=${selectedPlace?.formatted_address?.replace(/ /g, '+')}&service=${serviceSelected?.fullName?.replace(/ /g, '+')}&radius=${radius?.label}`;
    let url;
    closeInfoWindow();
    /* The code is checking if the variable `selectedPlace` is `string`. This is the value entered by the user in the search bar but not selected the location from the autocomplete. */
    if (typeof selectedPlace === 'string') {
      const response = await getLocLatLong(selectedPlace, GoogleMapsApiKey);
      const location = response?.results?.[0];
      if (response) {
        setSelectedPlace(location);
        setInitialPlace(location);
        /* The code is constructing a URL string for a specific endpoint `/find-an-office` with query
    parameters based on the values of `office` which is pulled from office api, `serviceSelected`, and `radius`. */
        url = getUrl(location, radius);
      }
    } else {
      url = getUrl(selectedPlace, radius);
    }
    window.history.replaceState(null, '', url);
    setButtonClick(true);
    scrollToMapContainer();
    setFindOfficeClick(true);
  };

  const closeInfoWindow = () => {
    if (officeMapRef?.current) {
      officeMapRef.current?.handleInfoWindowClose();
    }
  };
  const scrollToMapContainer = () => {
    if (officeMapRef?.current) {
      officeMapRef.current.scrollToMapContainer();
    }
  };

  const getUrl = (location: Place, radius: MarksProps | undefined) => {
    if (radius) {
      const serviceValue = trimSpaces(serviceSelected?.fullName)
        ?.replace(/[^\w\s]/g, ' ')
        ?.replace(/ /g, '+');
      const url = `/find-an-office?address=${location?.formatted_address?.replace(/[#\\/\s]/g, '+')}&service=${serviceValue}&radius=${radius?.label}`;
      return url;
    }
  };

  /**
   * Fetches office locations based on the selected criteria.
   * @param {google.maps.places.PlaceResult | null} place - The selected place object.
   */
  const fetchOffices = useCallback(
    async (
      placeSelected: google.maps.places.PlaceResult | null | undefined
    ) => {
      if (serviceSelected && buttonClick && (address || stageValue)) {
        const place = placeSelected ?? selectedPlace;
        if (typeof place !== 'string') {
          setLoading(true);
          setOffices([]);
          try {
            const { lat, lng } = place?.geometry?.location ?? {};
            const latitude = typeof lat === 'number' ? lat : lat?.();
            const longitude = typeof lng === 'number' ? lng : lng?.();
            const proximity = marks
              ?.find((item: MarksProps) => item?.value === stageValue)
              ?.label?.toString();
            const data = await getOfficeLocations(
              latitude,
              longitude,
              proximity,
              OfficeApibaseURL
            );

            /*Map the API response to the Office model. */
            const officeData = data?.Response?.map((office: OfficeApiModel) => {
              return mapOfficeAPI(office);
            });

            /*Filter offices that have selected services*/
            const offices = officeData
              ?.filter(Boolean)
              .filter((office: OfficeModel) => {
                return office?.services.find((service) =>
                  service
                    ?.toLowerCase()
                    ?.includes(
                      trimSpaces(serviceSelected?.shortName)?.toLowerCase()
                    )
                );
              });
            setOffices(offices);
            setLoading(false);
          } catch (error) {
            setLoading(false);
            setOffices([]);
          }
          setButtonClick(false);
        } else {
          setLoading(false);
        }
      }
    },
    [buttonClick, stageValue]
  );

  /**
   * Fetches office data from the query parameters and updates the component state accordingly.
   */
  const fetchOfficeDataFromQuery = useCallback(async () => {
    try {
      if (address || service) {
        /* Get location from Lat & Long if the address is available, else get current location */
        const locationResponse = address
          ? await getLocLatLong(address?.replace(/-/g, ' '), GoogleMapsApiKey)
          : getCurrentLocation();
        const locationData = locationResponse?.results?.[0];
        if (locationData) {
          setInitialPlace(locationData);
          setSelectedPlace(locationData);
          setIsAutocompleteValid(true);
        }
        const selectedService = serviceList?.find(
          (item) => trimSpaces(item.fullName) === service
        );
        const selectServ = serviceList?.map((item) => {
          if (
            service
              ?.toLowerCase()
              ?.includes(
                trimSpaces(
                  item?.fullName?.toLowerCase()?.replace(/[^\w\s]/g, ' ')
                )
              )
          ) {
            const altServ = { ...item, isSelected: true };
            return altServ;
          }
          return item;
        });
        setServiceList(selectServ);
        if (selectedService) {
          selectedService.isSelected = true;
        }
        setButtonClick(true);
        setFindOfficeClick(true);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    // Dependencies include params.address, params.service, and serviceList
  }, [address, service, serviceList]);

  /**
   * Retrieves office data based on the user's location or query parameters.
   * If query parameters are provided, it fetches office data based on the address specified in the parameters.
   * If no query parameters are provided, it attempts to fetch office data based on the user's current geolocation.
   * If geolocation is not available or the user denies access, it logs an error message.
   */
  const getOffices = () => {
    if (address || service) {
      fetchOfficeDataFromQuery();
    }
  };

  useEffect(() => {
    getOffices();
  }, [searchParams]);

  useEffect(() => {
    if (!address && !service) {
      setOffices([]);
      getCurrentLocation();
      const stage = marks?.find(
        (mark: MarksProps) => mark?.label === Number(defaultRadius)
      )?.value;
      setStageValue(stage);
      setServiceList(
        serviceList?.map((item) => ({ ...item, isSelected: false }))
      );
      setFindOfficeClick(false);
    }
  }, [searchParams, marks]);

  const getCurrentLocation = () => {
    if (window && window?.google && navigator?.geolocation) {
      navigator?.geolocation?.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords || {};
          const geocoder = new window.google.maps.Geocoder();
          const latLng = new window.google.maps.LatLng(latitude, longitude);
          geocoder.geocode({ location: latLng }, (results, status) => {
            if (status === 'OK' && results && results?.length >= 0) {
              const locationData = results?.[0];
              setInitialPlace(locationData);
              setSelectedPlace(locationData);
              setIsAutocompleteValid(true);
            }
          });
        },
        (error) => {
          setInitialPlace(undefined);
          console.error('Error getting user location:', error);
        }
      );
    }
  };

  /**
   * Handles the change event when the user adjusts the stage slider.
   * This function calculates the closest allowed value based on the selected value.
   * The closest allowed value is determined based on the available radius options.
   * @param {Event} event - The event object triggered by the slider change.
   * @param {number | number[]} newValue - The new value selected on the slider.
   */
  const handleStageChange = (event: Event, newValue: number | number[]) => {
    closeInfoWindow();
    const allowedValues = radiusInMilesOptions?.map((element) => {
      return radiusInMilesOptions?.indexOf(element);
    });

    const closestAllowedValue = allowedValues?.reduce((a, b) =>
      Math.abs(b - (newValue as number)) < Math.abs(a - (newValue as number))
        ? b
        : a
    );

    setStageValue(closestAllowedValue);
    const radius = marks?.at(closestAllowedValue ?? 1);
    if (typeof selectedPlace !== 'string' && findOfficeClick) {
      const url = getUrl(selectedPlace, radius);
      window.history.replaceState(null, '', url);
      setButtonClick(true);
    }
  };

  const updateServiceList = (selectedItem?: string) => {
    const selectedService = serviceList?.map((item) => {
      if (item?.fullName === selectedItem) {
        const altServ = { ...item, isSelected: !item?.isSelected };
        return altServ;
      }
      return { ...item, isSelected: false };
    });
    setServiceList(selectedService);
  };

  useEffect(() => {
    typeof selectedPlace !== 'string' && fetchOffices(selectedPlace);
  }, [buttonClick, stageValue]);

  const handleAutocompleteChnge = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSelectedPlace(event?.target?.value);
    setButtonClick(false);
    const isValid = isAutoCompleteFieldValid(event?.target?.value);
    setIsAutocompleteValid(isValid);
  };

  const handleGetLocation = (loc: Place) => {
    setIsAutocompleteValid(true);
    setSelectedPlace(loc);
    setButtonClick(true);
  };
  return (
    <div className="flex flex-col">
      <ResetScrollRestore />
      {props && (
        <GoogleMapContextProvider>
          <div
            {...inspectorProperties({
              entryId: updatedState?.sys?.id,
              fieldId:
                updatedState?.fields?.internalName ?? updatedState?.fields?.name
            })}
            className="app-section relative flex blue-gradient  "
          >
            <Container id="office-search" fixed className="relative mx-auto">
              <div className="flex flex-col  gap-8 justify-between py-8  ">
                <div className="top grid grid-cols-12 gap-6  ">
                  <div className="col-span-12 sm:col-span-6 relative">
                    <h2 className="t-16-17 mb-4  font-semibold color-ba-primary-black">
                      {locationLabel}
                      <span className="ml-1 color-ba-primary-900">*</span>
                    </h2>
                    <PlacesAutocomplete
                      showLabel={true}
                      initialPlace={initialPlace}
                      onSelect={handlePlaceSelection}
                      hasSubmitButton={false}
                      hasInitialLocation={true}
                      locationInputLabel={locationInputLabel}
                      handleAutocompleteChange={handleAutocompleteChnge}
                      id="find-an-office"
                      getLocation={handleGetLocation}
                    />
                  </div>
                  <div className="mx-auto relative col-span-12 sm:col-span-6 lg:col-start-8 lg:col-end-12  w-full ">
                    {radiusLabel && (
                      <RadiusSlider
                        radiusLabel={radiusLabel}
                        stageValue={stageValue}
                        handleStageChange={handleStageChange}
                        setMarks={handleMarks}
                        radiusInMilesOptions={radiusInMilesOptions}
                      />
                    )}
                  </div>
                </div>
                <div className="bottom relative">
                  <h2 className="t-16-17 mb-4  font-semibold color-ba-primary-black">
                    {serviceTypeLabel}
                    <span className="ml-1 color-ba-primary-900">*</span>
                  </h2>
                  <div className=" flex flex-wrap gap-2  ">
                    {serviceList?.map(
                      (service: serviceDataProps, index: number) => (
                        <CustomChip
                          key={index}
                          label={service.fullName}
                          bg={'var(--white)'}
                          sx={{
                            borderRadius: '0.25rem'
                          }}
                          className="rounded-none"
                          isMultiSelect={false}
                          isClicked={service?.isSelected}
                          chipValue={service?.fullName.toString()}
                          handleChipClick={handleOnChipClick}
                        />
                      )
                    )}
                  </div>
                  <div className="mt-6 flex gap-x-6 gap-y-3 items-center flex-wrap md:flex-nowrap">
                    <Button
                      variant="contained"
                      color="primary"
                      disableFocusRipple
                      className="office-btn"
                      onClick={handleFindOffice}
                      disabled={!serviceSelected || !isAutocompleteValid}
                      aria-label={findOfficeButtonAriaLabel}
                      sx={{
                        color: 'var(--white)!important'
                      }}
                    >
                      {findOfficeButtonLabel}
                    </Button>
                    {!serviceSelected ? (
                      <p className="t-15 color-ba-gray-900 mb-0">
                        {selectServiceHelptext}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </Container>
          </div>
          <OfficeMapContainer
            id="office-list"
            officePageData={findOfficePageData}
            ref={officeMapRef}
            loading={loading}
            officeData={offices}
            initialPlace={initialPlace}
            selectedService={serviceSelected}
            address={address}
            service={service}
          />
        </GoogleMapContextProvider>
      )}
    </div>
  );
};
